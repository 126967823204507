import { getGatsbyImageData } from "@imgix/gatsby"
import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyCardGrid } from "../../../slices/PageDataBodyCardGrid"

type Card = React.ComponentPropsWithoutRef<
	typeof PageDataBodyCardGrid
>["cards"][number]

const getCardImage = (data: { src: string; aspectRatio: number }) => {
	return getGatsbyImageData({
		width: 700,
		layout: "fullWidth",
		breakpoints: [234, 500, 700],
		...data,
	})
}

const card = (data: Partial<Card> = {}): Card => {
	return {
		href: "#",
		image: getCardImage({
			src: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
			aspectRatio: 1513 / 1008,
		}),
		imageAlt: "",
		heading: lorem.words(2),
		subheading: lorem.words(4),
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyCardGrid
				cards={[
					card(),
					card({
						image: getCardImage({
							src: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2",
							aspectRatio: 673 / 1008,
						}),
					}),
					card({
						image: getCardImage({
							src: "https://images.unsplash.com/photo-1508243529287-e21914733111",
							aspectRatio: 800 / 533,
						}),
					}),
				]}
				heading={lorem.words(4)}
				copy={
					[
						{ text: lorem.sentences(2), type: "paragraph", spans: [] },
					] as pt.RichTextField
				}
			/>
		</CmsGuideLayout>
	)
}

export default Component
